.upload-materias .plan-info {
  width: 458px;
  border: #E5E5E5 1px solid;
  background: #FFFFFF;
  text-align: left;
  font-size: 14px;
}
.upload-materias .plan-info .title {
    height: 30px;
    line-height: 30px;
    border-bottom: #E5E5E5 1px solid;
    padding-left: 20px;
}
.upload-materias .plan-info ul {
    line-height: 30px;
    padding-left: 20px;
}
.upload-materias .upload-form {
  width: 458px;
  margin-bottom: 68px;
}
.upload-materias .upload-form .el-form-item__label {
    position: absolute;
    left: 0;
    z-index: 9;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
}
.upload-materias .upload-form .file-upload {
    margin-top: 22px;
    position: relative;
}
.upload-materias .upload-form .file-upload .el-form-item__content {
      border: #E5E5E5 1px solid;
      margin-top: 50px;
      padding: 50px 0;
      background: #FFFFFF;
}
.upload-materias .upload-form .photo-upload {
    text-align: left;
    position: relative;
}
.upload-materias .upload-form .photo-upload .el-form-item__content {
      margin-top: 50px;
}
.upload-materias .upload-form .comments {
    position: relative;
}
.upload-materias .upload-form .comments .el-textarea {
      margin-top: 50px;
}
.upload-materias .upload-form .comments .el-textarea textarea {
        height: 160px;
}
